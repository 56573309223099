module.exports = {
  /**
   * @description 网站标题
   */
  title: '创伤急救综合练、考一体化管理平台',
  /**
   * @description 是否显示 tagsView
   */
  tagsView: true,
  /**
   * @description 固定头部
   */
  fixedHeader: true,
  /**
   * @description 记住密码状态下的token在Cookie中存储的天数，默认1天
   */
  tokenCookieExpires: new Date(Date.now() + 60 * 60 * 1000), // 设置过期时间为从现在起的一小时后,
  /**
   * @description 记住密码状态下的密码在Cookie中存储的天数，默认1天s
   */
  passCookieExpires: 1,
  /**
   * @description 是否只保持一个子菜单的展开
   */
  uniqueOpened: true,
  /**
   * @description token key
   */
  TokenKey: 'HANISUN-TOEKN',
  /**
   * @description 请求超时时间，毫秒（默认2分钟）
   */
  timeout: 1200000,
  /**
   * @description 是否显示logo
   */
  sidebarLogo: false,
  /**
   * 是否显示设置的底部信息
   */
  showFooter: true,
  /**
   * 底部文字，支持html语法
   */
  footerTxt: '技术支持： <a href="https://hanisun.com/" target="_blank">天津瀚海星云数字科技股份有限公司</a>',
  /**
   * 备案号
   */
  // caseNumber: '浙ICP备18005431号'
}
