import request from '@/utils/request'

export function login(username, password) {
  return request({
    url: 'auth/simpleLogin',
    method: 'post',
    data: {
      username,
      password,
    }
  })
}
//！获取验证码
export function getCode(phone) {
  return request({
    url: `/auth/sms/code?mobile=${phone}`,
    method: 'get'
  })
}
//!手机号登录
export function phoneLogin(phone, code) {
  return request({
    url: '/auth/login/mobile',
    method: 'post',
    data: {
      phone,
      code
    }
  })
}
//!注册
export function register(phone, code, nickName, deptId) {
  return request({
    url: '/api/users/register/mobile',
    method: 'post',
    data: {
      phone,
      code,
      nickName,
      deptId,
      jobId: 8,
    }
  })
}
//!部门列表
export function getInstitution() {
  return request({
    url: '/api/users/basicinfo',
    method: 'get',
  })
}
export function getInfo() {
  return request({
    url: 'auth/info',
    method: 'get'
  })
}

export function getCodeImg() {
  return request({
    url: 'auth/code',
    method: 'get'
  })
}

export function logout() {
  return request({
    url: 'auth/logout',
    method: 'delete'
  })
}
