<template>
  <div>
    <!--加载花瓣-->
    <div id="spinner"></div>
    <!--页面内容-->
    <div class="container-fluid" id="container">
      <div id="loginBox">
        <img src="../../public/resources/images/底框.png" />
        <div class="title">
          <div class="reg" style="color: #4f78e0" v-show="isVisible3 == true">
            注册
          </div>
        </div>
        <div class="title2">
          <div class="passwordLogin" @click="toggle" :class="{ active: isVisible == true }"
            v-show="isVisible3 == false">
            密码登录
          </div>
          <span class="line" v-show="isVisible3 == false"></span>
          <div class="phoneLogin" @click="toggle2" :class="{ active: isVisible2 == true }" v-show="isVisible3 == false">
            手机号登录
          </div>
        </div>
        <!-- !账号密码登录 -->
        <el-form  ref="loginForm" :model="loginForm" :rules="loginRules" @keyup.enter.native="fnSubmit"
          v-show="isVisible == true" style="position: relative; top: 53%; transform: translateY(-50%)">
          <el-form-item prop="username">
            <img src="../../public/resources/images/account.png" />
            <input type="text" placeholder="请输入账号" id="username" v-model="loginForm.username" />
          </el-form-item>
          <el-form-item prop="password">
            <img src="../../public/resources/images/passward.png" />
            <input type="password" placeholder="请输入密码" id="password" v-model="loginForm.password" />
          </el-form-item>

          <el-form-item>
            <el-button class="btn btn-primary" id="submit" @click="fnSubmit">
              登录
            </el-button>
          </el-form-item>
        </el-form>
        <!-- !账号密码登录结束 -->
        <!-- !手机验证码登录  -->
        <el-form style=" width: 360px;position: relative; top: 53%; transform: translateY(-50%)" ref="loginForm2" :model="loginForm2"
          :rules="loginRules" @keyup.enter.native="fnSubmitCode" v-show="isVisible2 == true">
          <el-form-item prop="phoneNum">
            <img src="../../public/resources/images/icon_手机号.png" />
            <input type="text" placeholder="请输入手机号" id="phoneNum" v-model="loginForm2.phoneNum" />
          </el-form-item>
          <el-form-item prop="code" style="position: relative">
            <img src="../../public/resources/images/icon_验证码.png" />
            <input type="code" placeholder="请输入验证码" id="code" v-model="loginForm2.code" />
            <button type="button" class="codeBtn" @click="getCode" :class="{ 'disabled-style': getCodeBtnDisable }"
              :disabled="getCodeBtnDisable">
              {{ codeBtnWord }}
            </button>
          </el-form-item>

          <el-form-item>
            <el-button class="btn btn-primary" id="submit" @click="fnSubmitCode">
              登录
            </el-button>
          </el-form-item>
        </el-form>
        <!-- !手机验证码登录 结束 -->
        <!-- !注册-->
        <el-form class="regForm" ref="regForm" :model="regForm" :rules="loginRules" @keyup.enter.native="fnSubmitReg"
          v-show="isVisible3 == true">
          <el-form-item prop="username">
            <img src="../../public/resources/images/icon_姓名.png" />
            <input type="username" placeholder="请输入姓名" id="username" v-model="regForm.username" />
          </el-form-item>
          <el-form-item prop="phoneNum">
            <img src="../../public/resources/images/icon_手机号.png" />
            <input type="text" placeholder="请输入手机号" id="phoneNum" v-model="regForm.phoneNum" />
          </el-form-item>
          <el-form-item prop="code" style="position: relative">
            <img src="../../public/resources/images/icon_验证码.png" />
            <input type="code" placeholder="请输入验证码" id="code" v-model="regForm.code" />
            <button type="button" class="codeBtn" @click="getRegCode" :class="{ 'disabled-style': getCodeBtnDisable2 }"
              :disabled="getCodeBtnDisable2">
              {{ codeBtnWord2 }}
            </button>
          </el-form-item>
          <div class="selectDiv">
            <!-- <el-form-item prop="position">
              <el-select
                v-model="regForm.position"
                placeholder="岗位"
                style="width: 120px"
              >
                <el-option label="区域一" value="shanghai"></el-option>
                <el-option label="区域二" value="beijing"></el-option>
              </el-select>
            </el-form-item> -->
            <el-form-item prop="institution">
              <el-cascader class="cascaderInput" style="width: 97%; margin-left: 5px" v-model="regForm.institution"
                :options="regForm.options" :props="regForm.optionProps" placeholder="组织机构"
                v-set-aria-hidden="false"></el-cascader>
            </el-form-item>
          </div>
          <el-form-item>
            <el-button style="margin-top: 3px" class="btn btn-primary" id="submit" @click="fnSubmitReg">
              注册
            </el-button>
          </el-form-item>
        </el-form>
        <!-- !注册结束 -->
        <div class="regMsg2" @click="toggle3" v-if="isVisible3 == false">
          <span>没有账号？</span><span>立即注册</span>
        </div>
        <div class="regMsg" @click="toggle" v-else>
          <span>已有账号？</span><span>立即登录</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getCode, getInstitution } from "@/api/login";
export default {
  name: "login",
  data() {
    return {
      isVisible: true, // 控制显示与隐藏的布尔值
      isVisible2: false, // 控制显示与隐藏的布尔值
      isVisible3: false, // 控制显示与隐藏的布尔值
      loginForm: {
        username: "",
        password: "",
        rememberMe: true,
      },
      loginForm2: {
        phoneNum: "",
        code: "",
        // rememberMe: true,
      },
      regForm: {
        username: "",
        phoneNum: "",
        code: "",
        //性别
        resource: "",
        // //岗位
        // position: "",
        // //角色
        // character: "",
        //机构
        institution: [],
        options: [],
        optionProps: {
          value: "id",
          label: "name",
          children: "children",
          // checkStrictly: true, //选择任意一级
        },
        // rememberMe: true,
      },
      //手机号登录验证码
      codeBtnWord: "获取验证码", // 获取验证码按钮文字
      waitTime: 121, // 获取验证码按钮失效时间
      //注册手机验证码
      codeBtnWord2: "获取验证码", // 获取验证码按钮文字
      waitTime2: 121, // 获取验证码按钮失效时间
      loginRules: {
        username: [
          { required: true, trigger: "blur", message: "用户名不能为空" },
        ],
        password: [
          { required: true, trigger: "blur", message: "密码不能为空" },
        ],
        phoneNum: [
          { required: true, trigger: "blur", message: "手机号不能为空" },
        ],
        code: [{ required: true, trigger: "blur", message: "验证码不能为空" }],
        resource: [{ required: true, trigger: "blur", message: "请选择性别" }],
        // character: [{ required: true, trigger: "blur", message: "请选择角色" }],
        // position: [{ required: true, trigger: "blur", message: "请选择岗位" }],
        institution: [
          { required: true, trigger: "blur", message: "请选择机构" },
        ],
      },
    };
  },
  directives: {
    "set-aria-hidden": {
      bind(el, binding) {
        const cascaderInput = el.querySelector(".el-input__inner");
        cascaderInput.setAttribute("aria-hidden", binding.value);
      },
    },
  },
  methods: {
    //获取部门列表内容
    //密码登录提交
    fnSubmit() {
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          const user = {
            username: this.loginForm.username,
            password: this.loginForm.password,
            rememberMe: this.loginForm.rememberMe,
          };

          this.openLoading();
          this.$store
            .dispatch("Login", user)
            .then(() => {
              this.closeLoading();
              this.$router.push({ path: this.redirect || "/" });
            })
            .catch((err) => {
             if (err.response.data.status==400) {
               this.$message({
                message: `登录失败：账号或者密码错误`,
                type: "error",
                center: true,
              });
             }else{
              this.$message({
                message: `登录失败：${err.response.data.message}}`,
                type: "error",
                center: true,
              });
             }
              console.log(err);
              this.closeLoading();
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });

      // url为后端对接接口。参数（account,password）。
      // this.common.fnGetdata(url,{account:this.account,password:this.password},'POST',true,3000,this.fnGet,this)
      //this.$router.push({ name: "Main" });
    },
    //手机号登录提交
    fnSubmitCode() {
      this.$refs.loginForm2.validate((valid) => {
        if (valid) {
          const userCode = {
            phoneNum: this.loginForm2.phoneNum,
            code: this.loginForm2.code,
            // rememberMe: this.loginForm.rememberMe,
          };

          this.openLoading();
          this.$store
            .dispatch("GetCodeLogin", userCode)
            .then(() => {
              this.closeLoading();
              this.$router.push({ path: this.redirect || "/" });
            })
            .catch((err) => {
              this.$message({
                message: `登录失败：${err.response.data.message}`,
                type: "error",
                center: true,
              });
              console.log(err.status);
              this.closeLoading();
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });

      // url为后端对接接口。参数（account,password）。
      // this.common.fnGetdata(url,{account:this.account,password:this.password},'POST',true,3000,this.fnGet,this)
      //this.$router.push({ name: "Main" });
    },
    //注册后登录提交
    fnSubmitReg() {
      this.$refs.regForm.validate((valid) => {
        if (valid) {
          const regUserInfo = {
            nickName: this.regForm.username,
            phone: this.regForm.phoneNum,
            code: this.regForm.code,
            // resource: this.regForm.resource,
            // position: this.regForm.position,
            // character: this.regForm.character,
            deptId: `${this.regForm.institution[this.regForm.institution.length - 1]
              }`,
          };
          const userCode = {
            phoneNum: this.regForm.phoneNum,
            code: this.regForm.code,
          };
          this.openLoading();
          //注册成功后调用登录接口直接登录
          this.$store
            .dispatch("Register", regUserInfo)
            .then(() => {
              this.closeLoading();
              this.$message({
                message: `注册成功，登录中,请稍等`,
                type: "success",
                center: true,
              });
              console.log(userCode);
              this.$store
                .dispatch("GetCodeLogin", userCode)
                .then(() => {
                  this.closeLoading();
                  this.$router.push({ path: this.redirect || "/" });
                })
                .catch((err) => {
                  this.$message({
                    message: `登录失败：${err.response.data.message}`,
                    type: "error",
                    center: true,
                  });
                  console.log(err.status);
                  this.closeLoading();
                });
            })
            .catch((err) => {
              if (err.response && err.response.data) {
                const message = err.response.data.message;
                const regex = /User with phone (\d+) existed/;
                const match = message.match(regex);

                if (match) {
                  const existingPhoneNumber = match[1];
                  this.$message({
                    message: `用户已存在，手机号码: ${existingPhoneNumber}`,
                    type: "error",
                    center: true,
                  });
                  // 处理用户已存在的逻辑
                } else {
                  this.$message({
                    message: `其他错误: ${err.response.data.message}`,
                    type: "error",
                    center: true,
                  });
                  console.error("其他错误:", err.response.data.message);
                  // 处理其他错误
                }
              } else {
                this.$message({
                  message: `网络错误: ${err}`,
                  type: "error",
                  center: true,
                });
                console.error("网络错误:", err);
                // 处理网络错误
              }
              console.log(err);
              this.closeLoading();
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });

      // url为后端对接接口。参数（account,password）。
      // this.common.fnGetdata(url,{account:this.account,password:this.password},'POST',true,3000,this.fnGet,this)
      //this.$router.push({ name: "Main" });
    },

    //获取登录验证码
    async getCode() {
      // 关闭密码登录模态框
      this.isVisible = false; // 切换布尔值的状态
      this.isVisible2 = true; // 切换布尔值的状态
      this.isVisible3 = false; // 切换布尔值的状态

      if (this.phoneNumberStyle) {
        let params = {};
        params.phone = this.loginForm2.phoneNum;

        try {
          await getCode(params.phone);
          this.$message({
            message: "验证码已发送，请稍候...",
            type: "success",
            center: true,
          });

          // 因为下面用到了定时器，需要保存this指向
          let that = this;
          that.waitTime--;
          that.getCodeBtnDisable = true;
          this.codeBtnWord = `${this.waitTime}s 后重新获取`;

          let timer = setInterval(function () {
            if (that.waitTime > 1) {
              that.waitTime--;
              that.codeBtnWord = `${that.waitTime}s 后重新获取`;
            } else {
              clearInterval(timer);
              that.codeBtnWord = "获取验证码";
              that.getCodeBtnDisable = false;
              that.waitTime = 121;
            }
          }, 1000);
        } catch (error) {
          console.error("获取验证码失败:", error);
          this.$message({
            message: "获取验证码失败，请勿重复获取，或检查网络设置。",
            type: "error",
            center: true,
          });
        }
      } else {
        this.$message({
          message: "请输入正确的手机号",
          type: "error",
          center: true,
        });
      }
    },
    //获取注册验证码
    async getRegCode() {
      if (this.phoneNumberStyle2) {
        let params = {};
        params.phone = this.regForm.phoneNum;
        try {
          await getCode(params.phone);
          this.$message({
            message: "验证码已发送，请稍候...",
            type: "success",
            center: true,
          });
          // 因为下面用到了定时器，需要保存this指向
          let that = this;
          that.waitTime2--;
          that.getCodeBtnDisable2 = true;
          this.codeBtnWord2 = `${this.waitTime2}s 后重新获取`;
          let timer = setInterval(function () {
            if (that.waitTime2 > 1) {
              that.waitTime2--;
              that.codeBtnWord2 = `${that.waitTime2}s 后重新获取`;
            } else {
              clearInterval(timer);
              that.codeBtnWord2 = "获取验证码";
              that.getCodeBtnDisable2 = false;
              that.waitTime2 = 121;
            }
          }, 1000);
        } catch (error) {
          console.error("获取验证码失败:", error);
          this.$message({
            message: "获取验证码失败，请勿重复获取，或检查网络设置。",
            type: "error",
            center: true,
          });
        }
      } else {
        this.$message({
          message: "请输入正确的手机号",
          type: "error",
          center: true,
        });
      }
    },

    //切换个模块的显示与隐藏
    toggle() {
      // 打开密码登录模态框
      this.isVisible = true; // 切换布尔值的状态
      // 关闭验证码登录模态框
      this.isVisible2 = false; // 切换布尔值的状态
      // 关闭注册模态框
      this.isVisible3 = false; // 切换布尔值的状态
    },
    toggle2() {
      // 关闭密码登录模态框
      this.isVisible = false; // 切换布尔值的状态
      this.isVisible2 = true; // 切换布尔值的状态
      this.isVisible3 = false; // 切换布尔值的状态
    },
    toggle3() {
      this.isVisible = false; // 切换布尔值的状态
      this.isVisible2 = false; // 切换布尔值的状态
      this.isVisible3 = true; // 切换布尔值的状态
    },
  },

  computed: {
    // 用于校验手机号码格式是否正确
    phoneNumberStyle() {
      let reg = /^1(3|4|5|6|7|8|9)\d{9}$/;

      if (!reg.test(this.loginForm2.phoneNum)) {
        return false;
      }
      return true;
    },
    // 用于校验注册手机号码格式是否正确
    phoneNumberStyle2() {
      let reg = /^1(3|4|5|6|7|8|9)\d{9}$/;

      if (!reg.test(this.regForm.phoneNum)) {
        return false;
      }
      return true;
    },
    // 控制手机号获取验证码按钮是否可点击
    getCodeBtnDisable: {
      get() {
        if (this.waitTime == 121) {
          if (this.loginForm2.phoneNum) {
            return false;
          }
          return true;
        }
        return true;
      },
      // 注意：因为计算属性本身没有set方法，不支持在方法中进行修改，而下面我要进行这个操作，所以需要手动添加
      set() { },
    },
    // 控制注册获取验证码按钮是否可点击
    getCodeBtnDisable2: {
      get() {
        if (this.waitTime2 == 121) {
          if (this.regForm.phoneNum) {
            return false;
          }
          return true;
        }
        return true;
      },
      // 注意：因为计算属性本身没有set方法，不支持在方法中进行修改，而下面我要进行这个操作，所以需要手动添加
      set() { },
    },
  },
  mounted() {
    getInstitution().then((res) => {
      this.regForm.options = res.depts;
      // console.log(this.regForm.options );
    });
  },
};
</script>

<style scoped>
html,
body {
  margin: 0;
  height: 100%;
}

input {
  outline: none;
}

#container {
  background-image: url("../../public/resources/images/loginBg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  height: 100vh;
  width: 100%;
}

#loginBox {
  width: 1000px;
  margin: auto;
  position: relative;
  top: 150px;
  height: 570px;
}

#loginBox>img {
  width: 100%;
  position: absolute;
  z-index: 1;
}

#loginBox>form {
  position: absolute;
  width: 360px !important;
  z-index: 99;
  top: 23%;
  left: 5%;
  width: 40%;
}

#loginBox>form>div>div {
  margin: 20px;
  position: relative;
  width: 100%;
}

#loginBox>form>div>div>img {
  position: absolute;
  left: 10px;
  top: 18px;
  width: 16px;
}

#username,
#password,
#phoneNum,
#code {
  border-radius: 5px;
  display: block;
  width: 100%;
  height: 50px;
  padding-left: 34px;
  font-size: 18px;
  line-height: 1.42857143;
  color: #22252c;
  font-family: "source Han Sans CN Ragular";
  /* background-color: #f7f8ff; */
  background-image: url("../../public/resources/images/input.png");
  border: 0;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

#submit {
  margin: 20px 0px;
  width: 100%;
  height: 60px;
  background-color: #4f78e0;
  color: #ffffff;
  font-size: 22px;
  font-family: "source Han Sans CN Bold";
  border: 1px solid #4f78e0;
  border-radius: 5px;
}

#submit:hover {
  background-color: #3b5fbd;
}

#submit:checked {
  background-color: #5e8cff;
}

input:focus {
  border: 1px solid #ccc;
}

input::-webkit-input-placeholder {
  color: #c3c7d1;
}

input::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #c3c7d1;
}

input:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #c3c7d1;
}

input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #c3c7d1;
}

.title {
  width: 37%;
  top: 90px;
  left: 53px;
  font-weight: 600;
  display: flex;
  position: absolute;
  z-index: 2;
  font-size: 22px;
  color: #c3c7d1;
  align-items: center;
}

.title div {
  cursor: pointer;
}

.title2 {
  width: 37%;
  top: 118px;
  left: 53px;
  font-weight: 600;
  display: flex;
  position: absolute;
  z-index: 2;
  font-size: 22px;
  color: #c3c7d1;
  align-items: center;
}

.title2 div {
  cursor: pointer;
}

.line {
  margin: 0px 13px;
  width: 4px;
  height: 20px;
  display: inline-block;
  background: #eaeaea;
}

.active {
  color: #4f78e0;
}

.codeBtn {
  position: absolute;
  top: 0;
  right: 0;
  height: 50px;
  width: 150px;
  border: none;
  background: #f7f8ff;
}

.disabled-style {
  /* background-color: #EEEEEE; */
  color: #cccccc;
}

.regMsg {
  z-index: 999;
  position: absolute;
  /* right: 550px; */
  left: 50px;
  bottom: 100px;
}

.regMsg span:last-child {
  color: #6185e2;
  cursor: pointer;
}

.regMsg2 {
    z-index: 999;
    position: absolute;
    right: 591px;
    bottom: 124px;
}

.el-input__inner {
  background: #f7f8ff !important;
  border: none !important;
}

.regMsg2 span:last-child {
  color: #6185e2;
  cursor: pointer;
}
.regForm{
  width: 360px;
}
.regForm .el-form-item {
  margin-bottom: 11px !important;
}

#loginBox>form>div>div {
  margin: 0px !important;
}

/* :deep(.el-form-item) */
.selectDiv {
  height: 50px;
  height: 50px;
  border-radius: 5px;
  background: #f7f8ff;
  display: flex;
  /* margin-top: -9px; */
  margin-bottom: 7px;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: center;
}

.timr.el-form .el-form-item__error {
  color: #f56c6c;
  font-size: 12px !important;
  line-height: 1;
  padding-top: 4px;
  position: absolute;
  top: 15px !important;
  right: -90px !important;
}

.cascaderInput>>>.el-input__inner {
  --el-radio-inner-aria-hidden: false !important;
  border: none;
  background-color: #f7f8ff !important;
  padding-left: 5px;
}

.cascaderInput>>>.el-input__inner::placeholder {
  color: #c3c7d1;
  font-size: 18px;
  margin-left: 30px;
}
</style>